<script>
import Layout from "../layouts/main.vue";
import PageHeader from "@/components/page-header";
import axios from 'axios';
import Multiselect from "vue-multiselect";
import { setValue, CurrencyDirective, getValue } from "vue-currency-input";
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    Layout,
    PageHeader,
    Multiselect
  },
  data() {
    return {
      title: "Dashboard",
      trx:[],
      desCon:'',
      submitted: false,
      destination_country:[],
      spesial:[],
      loading:true,
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [5, 10, 25, 50],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      sendAmount: null,
      receivedAmount: null,
      valueDesCon: null,
      balance :[],
      showmodal: false,
      showmodal2: false,
      showmodal3: false,
      historyLog:[],
      nominal:null,
      banks:[],
      bankid:'',
      document: null,
      isLoading:false,
      isDisabled: false,
      startDate:'',
      endDate:'',
      news:'',
      invs:[],
      inv:[],
      fields: [
        { key: "create_at", label:'Created' },
        { key: "invoice_num", label:'ID Invoice' },
        { key: "sender_name", label:"Sender Name" },
        { key: "recipient_name", label:"Recipient Name" },
        { key: "des_country", label:"Destination Country" },
        { key: "bank_sendto", label : "Bank Name" },
        { key: "primary", label:"Acc Number" },
        { key: "amount", label:"Send Amount" },
        { key: "status", label:"Status" },
        { key: "action"},
        // { key: "invoice"},
        // { key: "detail"}
      ],
      activityData:[],
      activityDatas:[],
      trans:[],
      isBusy: false,
    };
  },
  validations: {
    bankid: {
      required,
    },
  },
  computed: {
    rows() {
      return this.trx.length;
    }
  },
  mounted() {
    this.totalRows = this.trx.length;
  },
  methods: {
    handleSubmit(){
      this.isDisabled = true;
      this.submitted = true;
      this.$v.bankid.$touch();
      if(!this.$v.bankid.$invalid){
        let userID = JSON.parse(localStorage.getItem("user")).user_id;
        let name = JSON.parse(localStorage.getItem("user")).first_name + ' ' + JSON.parse(localStorage.getItem("user")).last_name;
        let amount = this.remove_format(this.nominal)
        let data = new FormData()
        data.append('name', name);
        data.append('nominal', amount);
        data.append('bank_id', this.bankid.id_bank);
        data.append('document', this.document);
        
        axios.post(`/v1/top-up/${userID}`,data)
        .then((res) => {
          if(res.data.success) {
            Swal.fire("Success", "Your request is being processing", "success");
            this.getNotif();
            this.showmodal2 = false;
            this.isDisabled = false;
            this.submitted = false;
          } else {
            Swal.fire("Failed ", res.data.message , "error");
            this.isDisabled = false;
            this.submitted = false;
          }
        })
      } else {
        Swal.fire("Warning", "Select Required", "error");
        this.isDisabled = false;
        this.submitted = false;
      }
    },  
    onChangebank(){
      this.nominal = ''
      this.bankid = ''
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    resetday(){
      this.startDate = 0
      this.endDate = 0
      this.getNotifall()
    },
    getCountry(){
      let id  = JSON.parse(localStorage.getItem("user")).user_id;
        axios.get(`v1/get-rate/${id}`)
        .then((res) => {
            this.destination_country = res.data.data
            // let newes = ''
            // for (let a = 0; a < this.destination_country.length; a++) {
            //   newes += this.destination_country[a].country + " : ";
            //   newes += (this.destination_country[a].country == 'Vietnam' ? this.formatPriceVND(this.destination_country[a].rate) : Number(this.destination_country[a].rate) >= 1000 ? this.formatPriceRP(this.destination_country[a].rate) : this.formatPrice(this.destination_country[a].rate));
            //   if (a != this.destination_country.length - 1) {
            //     newes += ", ";
            //   }
            // }  
          // this.news = newes
        })
        .catch((err) =>{
          if(err.message === "Network Error"){
            location.reload()
          }
          if(err.response.status == 401){
            location.reload()
          }
        })
    },
    getCountrysp(){
      let id  = JSON.parse(localStorage.getItem("user")).user_id;
        axios.get(`v1/get-sprate/${id}`)
        .then((res) => {
          this.loading = false;
            this.spesial = res.data.data
        })
    },
    getTransactionAFF(){
      this.isLoading = true;
      this.isBusy = true;
        let id  = JSON.parse(localStorage.getItem("user")).user_id;
        axios.get(`v1/get-trx-aff/${id}/0/0`)
        .then((res) => {
          this.isLoading=false;
          this.isBusy = false;
            this.trx = res.data.transaction
            this.trx.forEach(function (x) {
              x.primary = `${x.acc_number ? x.acc_number : x.iban ? x.iban : x.phone_n}`
          })
        })
    },
    getBank(){
        axios.get(`v1/bank-aff`)
        .then((res) => {
          this.banks = res.data.bank;
        })
    },
    getNotif(){
      let id  = JSON.parse(localStorage.getItem("user")).user_id;
      axios.get(`v1/get-notifs/${id}`)
      .then((res) => {
        this.activityData = res.data.notif;
      })
    },
    getNotifall(){
      let param = `0/0`;
      if (this.startDate && this.endDate) {
        param = `${this.startDate}/${this.endDate}`;
      }
      let id  = JSON.parse(localStorage.getItem("user")).user_id;
      axios.get(`v1/get-notifall/${id}/${param}`)
      .then((res) => {
        this.activityDatas = res.data.notif;
      })
    },
    getBal(){
      let id  = JSON.parse(localStorage.getItem("user")).user_id;
      axios.get(`v1/get-bal/${id}`)
      .then((res) => {
        this.balance = res.data.bal[0];
      })
    },
    formatPriceRP(value) {
      let val = (value / 1).toFixed(0).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formatPriceVND(value) {
      let val = (value / 1).toFixed(3).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    selected(event){
      let targetId = event;
      this.$router.push({ name: "send" , params:{country:targetId}})
    },
    selected2(event){
      let targetId = event;
      this.$router.push({ name: "special-send" , params:{country:targetId.country,rate:targetId.rate}})
    },
    submitDestinationCountry(option) {
      this.receivedAmount = "";
      this.sendAmount = "";
      
      this.valueDesCon = `${option.currency_code}-${option.rate}-${option.fee}-${option.country}`;
      
      let id_curr = `${option.currency_code}`
      axios.get(`/v1/get-field/1/${id_curr}`)
      .then((res) => {
        this.MinMax = res.data.currency
      })
    },
    hideModal() {
        this.nominal = '',
        this.bankid = '',
        this.document = '',
        this.showmodal2 = false;
    },
    currencyIndonesia() {
      let value = this.valueDesCon.split("-")[1];
      let data = getValue(this.$refs.receivedAmount) * Number(value);
      setValue(this.$refs.sendAmount, data);
      this.send_validation = getValue(this.$refs.receivedAmount);
    },

    currencyForeign() {
      
      let value = this.valueDesCon.split("-")[1];
      let data;
      if (getValue(this.$refs.sendAmount) >= Number(value)) {
        let first = getValue(this.$refs.sendAmount) / Number(value);
        data = Math.round((first + Number.EPSILON) * 100) / 100;
      } else {
        data = 0;
      }

      setValue(this.$refs.receivedAmount, data);
        this.send_validation = getValue(this.$refs.receivedAmount);
    },

    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
      console.log(evt)
    },
    toTransactionForm() {
      this.$router.push({
        name: "send",
      });
    },
    logBalance(){
      let id = JSON.parse(localStorage.getItem("user")).user_id;
      axios.get(`v1/historybal5/${id}`)
      .then((res) => {
        this.historyLog = res.data.user
      })
    },
    remove_format(money) {
      return money.toString().replace(/[^0-9]/g, "");
    },
    onChangeDocument(e){
      this.document = e.target.files[0];
    },
    download(e){
      let id = e.item.id_transaction;
      let url = process.env.VUE_APP_ROOT_DOWNLOAD;
      location.href=`${url}/v1/invoice/print/${id}`
    },
    detail(e){
      this.showmodal3 = true;
      this.trans = e.item;
      let id = e.item.invoice_num;
      axios.get(`v1/get-trx/${id}`)
        .then((res) => {
          this.invs = res.data.transaction
          this.invs.forEach(function(element) {
              element.created = `${element.create_at.substring(8,10)}-${element.create_at.substring(5,7)}-${element.create_at.substring(0,4)}`
          })
          this.inv = this.invs[0]
          this.getTransactionAFF();
        })
      // this.$router.push({ name: "invoice" , params:{id:id}})
    },
  },
  directives: {
    currency: CurrencyDirective,
  },
  created () {
    this.getTransactionAFF();
    this.getCountry();
    this.getBal();
    this.logBalance();
    this.getBank();
    this.getNotif();
    this.getNotifall();
    this.getCountrysp();
  },
};
</script>

<template>
  <Layout>
    <!-- <marquee class="marquee" behavior="scroll" direction="left">{{news}}</marquee> -->
    <PageHeader :title="title" />
    <div class="row space">
      <div class="col-xl-4 col-lg-6 col-md-6">
        <div v-if="balance.length != 0" class="card card-balance">
          <div class="card-body">
            <div class="media">
              <div class="media-body overflow-hidden">
                <h4 class="card-title mb-2">Balance</h4>
                <h3 class="mb-0">IDR {{formatPriceRP(this.balance.balance)}}</h3>
              </div>
              <div class="text-primary" style="width:4rem;margin-bottom:-2rem">
                  <i :class="`ri-wallet-3-fill font-size-55`"></i>
                  <!-- <span style="margin:.3rem;text-decoration:underline"><a href="javascript:void(0);" @click="showmodal = true">Details</a></span> -->
              </div>
            </div>
          </div>
          <div class="card-body border-top py-2 dashboard-topup d-flex justify-content-end pr-5">
            <div class="text">
                <a href="javascript:void(0);" @click="showmodal2 = true"
                  class="btn btn-primary chat-send w-md waves-effect waves-light"
                >
                  <i class="mdi mdi-plus"></i>
                  <span >  TOP UP</span>
                </a>
            </div>
          </div>
        </div>
        <b-skeleton-img v-else no-aspect height="10rem"></b-skeleton-img>

        <div v-if="balance.length != 0" class="card">
          <div class="card-body" style="height: 16.3rem;">
            <h4 class="card-title mb-1" style="float:left">Last 5 Detail Balance</h4>
            <a href="/balance" class="card-title mb-1" style="float:right;cursor:pointer;">See all Details</a>
            <div class="table-responsive"  style="margin-bottom:-1rem">
              <table class="table table-centered mb-0 table-nowrap table-striped">
                <thead class="bg-light">
                  <tr>
                    <th>Activity</th>
                    <th style="text-align:right">Debit / Kredit</th>
                    <th style="text-align:right">Last Balance</th>
                  </tr>
                </thead>
                <tbody class="lister">
                  <tr v-for="(data, index) in historyLog" :key="index">
                    <td><p>{{data.activity.substring(0,20) == 'Transaction Created ' ? "INV-" + data.activity.substring(21,36) : data.activity.substring(0,20) }}</p></td>
                    <td><p style="float:right">{{data.debit ? formatPriceRP(data.debit) : formatPriceRP(-data.kredit)}}</p></td>
                    <td><p style="float:right">{{formatPriceRP(data.balance)}}</p></td>
                  </tr>
                </tbody>
              </table>
            </div>  
          </div>
        </div>
        <b-skeleton-img v-else no-aspect height="17.3rem"></b-skeleton-img>
      </div>
      
      <div class="col-xl-3 col-lg-6 col-md-6">
        <div class="card" v-if="destination_country.length != 0">
          <div class="card-body">
            <h4 class="card-title mb-4">Send Money</h4>
            <div class="mb-3">
              <div class="input-group">
                <label class="form-label">Destination Country</label>
                <multiselect 
                  v-model="desCon" 
                  :options="destination_country" 
                  label="country"
                  :selectLabel="null"
                  :deselectLabel="null"
                  :allow-empty="false"
                  placeholder="Select Country"
                  required
                  @select="submitDestinationCountry"
                >
                  <template slot="singleLabel" slot-scope="{ option }"><img :src="option.flag" width="25" height="25"><span> {{ option.country }}</span></template>
                  <template slot="option" slot-scope="{ option }"><img :src="option.flag" width="25" height="25"><span> {{ option.country }}</span></template>
                </multiselect>
              </div>
            </div>
            <div class="mb-3">
              <label for="youSend" class="form-label">You Send</label>
              <div class="input-group">
                <input 
                  type="text" 
                  class="form-control" 
                  data-bv-field="youSend" 
                  id="youSend" 
                  placeholder="Enter Amount"
                  ref="sendAmount"
                  v-model="sendAmount"
                  autocomplete="off"
                  @keyup="currencyForeign"
                  required
                  v-currency="{
                    locale: 'default',
                    currency: null,
                    precision: 0,
                    distractionFree: false,
                    autoDecimalMode: false,
                    valueRange: { min: 0 },
                    allowNegative: false,
                  }"
                  >
                <span class="input-group-text"> IDR </span>
              </div>
            </div>
            <p class="text-muted mb-1"><img src="@/assets/images/rates-icon.svg" alt="Rates Icon" /> Rate 
              <span v-if="this.desCon" class="fw-500" style="float:right">{{this.desCon.country == 'Vietnam' ? formatPriceVND(this.desCon.rate) : this.desCon.rate >= 1000 ? formatPriceRP(this.desCon.rate) : formatPrice(this.desCon.rate)}} IDR</span>
              <span v-else class="fw-500" style="float:right">0 IDR</span>
            </p>
            <p class="text-muted"> <img src="@/assets/images/fee-icon.svg" alt="Rates Icon" /> Fee 
              <span v-if="this.desCon" class="fw-500" style="float:right">{{formatPriceRP(this.desCon.fee)}} IDR</span>
              <span v-else class="fw-500" style="float:right">0 IDR</span>
            </p>
            <div class="mb-3">
              <div class="input-group">
                <label for="received" class="form-label">Recipient Gets</label>
                <div class="input-group">
                  <input 
                    type="text" 
                    class="form-control" 
                    data-bv-field="received" 
                    id="received" 
                    placeholder="Enter Amount"
                    ref="receivedAmount"
                    v-model="receivedAmount"
                    autocomplete="off"
                    @keyup="currencyIndonesia"
                    @keypress="isNumber"
                    required
                    v-currency="{
                      locale: 'default',
                      currency: null,
                      precision: 2,
                      distractionFree: false,
                      autoDecimalMode: false,
                      valueRange: { min: 0 },
                      allowNegative: false,
                    }"
                    >
                  <span v-if="this.desCon" class="input-group-text"> {{this.desCon.currency_code.substring(0,3)}} </span>
                  <span v-else class="input-group-text" > IDR </span>
                </div>
              </div>
            </div>
              <router-link 
                  :to="{name: 'send', params:{country: this.desCon.country,send: sendAmount, rec: receivedAmount},}" 
                  class="btn btn-primary form-control"
                >
                  <span >  Send Money</span>
                </router-link>
          </div>
        </div>
        <b-skeleton-img v-else no-aspect height="27.3rem"></b-skeleton-img>
      </div>

      <div class="col-xl-3 col-lg-6 col-md-6">
        <div v-if="destination_country.length != 0" class="card">
          <div class="card-body">
              <h4 class="card-title mb-4">List Rate</h4>
              <div data-simplebar style="height: 22.3rem;" >
                <ul class="list-unstyled activity-wid">
                  <li v-for="(data, index) in destination_country" :key="index" class="hovered" v-on:click="selected(data.country)">
                    <div class="row list-rate">
                      <div class="col-2">
                        <img :src="data.flag" class="image-icon" alt="country_flag">
                      </div>
                      <div class="col-6">
                        <h5>{{data.country}}</h5>
                      </div>
                      <div class="col-4">
                        <h5>{{data.country == 'Vietnam' ? formatPriceVND(data.rate) : data.rate >= 1000 ? formatPriceRP(data.rate) : formatPrice(data.rate)}}</h5>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <b-skeleton-img v-else no-aspect height="27.3rem"></b-skeleton-img>
      </div>

      <div class="col-xl-2 col-lg-6 col-md-6">
        <div class="card">
          <div class="card-body" v-if="activityData.length != 0">
              <h4 class="card-title mb-4"><span style="float:left;">Last Status</span><a href="/notification" class="card-title mb-1" style="float:right;cursor:pointer;">See all</a></h4>
              <div data-simplebar style="height: 23.4rem;">
                <ul class="list-unstyled activity-wid">
                  <li class="activity-list" v-for="(data, index) in activityData" :key="index">
                    <div class="activity-icon avatar-xs">
                      <span v-if="data.title.includes('Completed')" class="avatar-title bg-soft-success text-success rounded-circle">
                        <i class="ri-bar-chart-fill"></i>
                      </span>
                      <span v-else-if="data.title.includes('Processing')" class="avatar-title bg-soft-warning text-warning rounded-circle">
                        <i class="ri-bar-chart-fill"></i>
                      </span>
                      <span v-else-if="data.title.includes('Rejected') || data.title.includes('Refunded') || data.title.includes('Cancelled')" class="avatar-title bg-soft-danger text-danger rounded-circle">
                        <i class="ri-bar-chart-fill"></i>
                      </span>
                      <span v-else class="avatar-title bg-soft-info text-info rounded-circle">
                        <i class="ri-bar-chart-fill"></i>
                      </span>
                    </div>
                    <div>
                      <div>
                        <h5 class="font-size-13">
                          {{data.create_at}}
                          <small class="text-muted">{{data.time}}</small>
                        </h5>
                      </div>

                      <div>
                        <p class="text-muted mb-0">{{data.title}}</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div v-else>
              <b-skeleton-img v-if="destination_country.length == 0" no-aspect height="27.3rem"></b-skeleton-img>
              <div v-else style="height: 27.4rem;">
                <h5 class="emptys">No Notification ...</h5>
              </div>
            </div>
          </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Transaction<a href="/transactions"></a>
            <a href="/transactions" class="card-title mb-1" style="float:right;cursor:pointer;">See all Transactions</a></h4>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <div class="table-responsive">
              <b-table
                  :items="trx"
                  :fields="fields"
                  striped
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                  :busy="isBusy"
                  show-empty
                  fixed
                >
                <template #table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{ width: field.key === 'bank_sendto' || field.key === 'recipient_name' || field.key === 'sender_name' ? '190px' : '125px' }"
                >
                </template>
                <template v-slot:cell(amount)="row">
                  <div
                  >{{row.item.currency_code}} {{ formatPrice(row.value)}}</div>
                </template>
                <template v-slot:cell(status)="row">
                  <div
                    class="badge font-size-12"
                    :class="{'badge-soft-danger': `${row.value}` === 'Order Cancelled',
                        'badge-soft-success': `${row.value}` === 'Transaction Completed',
                        'badge-soft-warning': `${row.value}` === 'Transaction Processing'}"
                  >{{ row.value}}</div>
                </template>
                <template v-slot:cell(action)="row">
                  <a
                    @click="download(row)"
                    href="javascript:void(0);"
                    :class="['mr-3',
                              {'text-success': `${row.item.status}` == 'Transaction Completed',
                                'text-danger' : `${row.item.status}` == 'Order Cancelled',
                                'text-warning' : `${row.item.status}` == 'Transaction Processing'}]"
                    v-b-tooltip.hover
                    title="Invoice"
                  >
                    <i class="mdi mdi-download" style="font-size:1.7rem"></i>
                  </a>
                  <a
                    @click="detail(row)"
                    href="javascript:void(0);"
                    class="text-primary"
                    v-b-tooltip.hover
                    title="Details"
                  >
                    <i class="mdi mdi-file-eye" style="font-size:1.7rem"></i>
                  </a>
                </template>
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle mr-2"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
                <template #empty>
                  <h5 class='empty'>No Transaction Data ...</h5>
                </template>
                <!-- <template v-slot:cell(invoice)="row">
                  <button @click="download(row)"
                  :class="['btn', 'btn-rounded',
                          {'btn-success': `${row.item.status}` == 'Transaction Completed',
                            'btn-danger' : `${row.item.status}` == 'Order Cancelled',
                            'btn-light' : `${row.item.status}` == 'Transaction Processing'}]"
                  >
                    <i class="mdi mdi-download"></i>
                  </button>
                </template>
                <template v-slot:cell(detail)="row">
                  <button @click="detail(row)" class="btn btn-primary btn-rounded">
                    <i class="mdi mdi-file-eye"></i>
                  </button>
                </template> -->
              </b-table>
            </div>
            <!-- <template v-else>
              <b-skeleton-img v-if="isLoading" no-aspect height="20rem"></b-skeleton-img>
              <h5 class='empty'>No Transaction Data ...</h5>
            </template> -->
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <!-- <b-modal
      id="modal-1"
      v-model="showmodal"
      title="Last Notification"
      title-class="text-dark font-18"
      scrollable
      hide-footer
      centered
      size="xl"
      @show="resetday"
      @hidden="resetday"
    >
      <form>
        <template>
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <div>
                    <label class="d-inline-flex align-items-center">
                    Date 
                    <b-form-input class="form-control form-control-sm ml-3" v-model="startDate" type="date" icon="">
                    </b-form-input>
                    <b-form-input class="form-control form-control-sm" v-model="endDate" type="date" icon="">
                    </b-form-input>
                    <b-button variant="info" class="form-control form-control-sm" @click="getNotifall"
                      >Submit</b-button>
                  </label>
                  </div>
                  <ul class="list-unstyled activity-wid">
                  <li class="activity-list" v-for="(data, index) in activityDatas" :key="index">
                    <div class="activity-icon avatar-xs">
                      <span v-if="data.title.includes('Completed')" class="avatar-title bg-soft-success text-success rounded-circle">
                        <i class="ri-bar-chart-fill"></i>
                      </span>
                      <span v-else-if="data.title.includes('Processing')" class="avatar-title bg-soft-warning text-warning rounded-circle">
                        <i class="ri-bar-chart-fill"></i>
                      </span>
                      <span v-else-if="data.title.includes('Rejected') || data.title.includes('Refunded') || data.title.includes('Cancelled')" class="avatar-title bg-soft-danger text-danger rounded-circle">
                        <i class="ri-bar-chart-fill"></i>
                      </span>
                      <span v-else class="avatar-title bg-soft-info text-info rounded-circle">
                        <i class="ri-bar-chart-fill"></i>
                      </span>
                    </div>
                    <div>
                      <div>
                        <h5 class="font-size-13">
                          {{data.create_at}}
                          <small class="text-muted">{{data.time}}</small>
                        </h5>
                      </div>

                      <div>
                        <p class="text-muted mb-0">{{data.title}}</p>
                      </div>
                    </div>
                  </li>
                </ul>
                </div>
              </div>
            </div>
          </div>
        </template>
      </form>
    </b-modal> -->
    <!-- end modal -->
    <b-modal
      id="modal-2"
      v-model="showmodal2"
      title="TOP UP"
      title-class="text-dark font-18"
      scrollable
      hide-footer
      centered
      size="lg"
      @show="onChangebank"
      @hidden="onChangebank"
    >
      <form @submit.prevent="handleSubmit">
        <template>
          <div class="card">
            <div class="card-body" style="min-height:20rem">
              <div class="row mb-2">
                <div class="col-4 mt-2">
                  <h5 for="topup">Amount TOP UP</h5>
                </div>
                <div class="col-8">
                  <input
                    id="topup"
                    v-model="nominal"
                    type="text"
                    class="form-control"  
                    placeholder="Enter Amount TOP UP"
                    required
                    autocomplete="off"
                    @keypress="isNumber"
                    v-currency="{
                      locale: 'default',
                      currency: null,
                      precision: 0,
                      distractionFree: false,
                      autoDecimalMode: false,
                      valueRange: { min: 0 },
                      allowNegative: false,
                    }"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-4 mt-2">
                  <h5>Select Bank Payment</h5>
                </div>
                <div class="col-8">
                  <multiselect
                    v-model="bankid" 
                    :options="banks" 
                    label="bank_name"
                    :selectLabel="null"
                    :deselectLabel="null"
                    :allow-empty="false"
                    placeholder="Select Bank Payment"
                    required
                  >
                  </multiselect>
                  <small
                    class="mb-0 text-danger"
                    v-if="
                      !$v.bankid.required &&
                      $v.bankid.$error
                    "
                  >
                    Select Bank Payment is required
                  </small>
                </div>
              </div>
              <div class="row" v-if="bankid">
                <div class="col-8 offset-4">
                  <div class="card bank-details">
                    <div class="row">
                      <div class="col-4">
                        <p>Account Holder</p>
                        <p>Account Number</p>
                        <p>Account Bank</p>
                      </div>
                      <div class="col">
                        <p>{{bankid.acc_name}}</p>
                        <p>{{bankid.no_rek}}</p>
                        <p>{{bankid.bank_name}}</p>
                      </div>
                    </div>
                  </div>
                  <small class="info-text">*Please pay with the registered account bank name</small>
                </div>
              </div>
              <div class="row">
                <div class="col-4 mt-3">
                  <h5>Upload Invoice</h5>
                </div>
                <div class="col-8 mt-2">
                  <input
                    type="file"
                    class="form-control"
                    @input="onChangeDocument"
                    placeholder="Upload Invoice Transfer"
                    required
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="text-right">
            <b-button class="ml-1" variant="secondary" @click="hideModal">Cancel</b-button>
            <button type="submit" class="btn btn-success ml-1" style="width:5rem" :disabled="isDisabled">
              <span v-if="submitted"><b-spinner class="p-0" style="width:20px;height:20px" variant="black" role="status"></b-spinner></span>
              <span v-else>Submit</span>
            </button>
          </div>
        </template>
      </form>
    </b-modal>

    <b-modal
      id="modal-1"
      v-model="showmodal3"
      title="Detail Transaction"
      title-class="text-dark font-18"
      scrollable
      hide-footer
      centered
      size="xl"
    >
      <form>
        <template>
          <b-row class="invoice-preview">
            <b-col cols="12" xl="12" md="12">
              <b-card no-body class="invoice-preview-card">
                <b-card-body class="invoice-padding pb-0">
                  <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
                    <div class="header-invoice">
                      <div class="logo-wrapper">
                        <img class="img" src="@/assets/images/logo adaremit.png" alt="">
                        <!-- <h3 class="text-primary invoice-logo">
                            Adaremit
                        </h3> -->
                      </div>
                      <p class="card-text pl-2">Jl. Iming 4T Medan</p>
                      <p class="card-text pl-2">Indonesia</p>
                      <p class="card-text pl-2">+62 811 6028 665</p>
                      <p class="card-text pl-2 mb-2">help@adaremit.co.id</p>
                    </div>
                    <div class="invoice-info mt-md-0 mt-2">
                      <h4 class="invoice-title">Invoice
                        <span class="invoice-number ml-5">#{{inv.invoice_num}}</span>
                      </h4>
                      <div class="invoice-date-wrapper">
                        <p class="invoice-date-title">Date</p>
                        <p class="invoice-date">{{inv.created}}</p>
                      </div>
                        <p class="invoice-date-title" style="margin-top:2rem" v-if="inv.bank_ref">Bank Reference :</p>
                        <p class="invoice-date" style="margin-top:-1rem" v-if="inv.bank_ref"><strong>{{inv.bank_ref}}</strong></p>
                    </div>
                  </div>
                </b-card-body>
                <hr class="invoice-spacing">

                <b-card-body class="invoice-padding pt-0">
                    <b-row class="invoice-spacing">
                        <b-col cols="12" xl="6" class="invoice-sender pb-5">
                            <h5 class="card-text">Sender :</h5>
                            <h6 class="card-text name">{{inv.sender_name ? inv.sender_name.toUpperCase() : '-'}}</h6>
                            <p class="card-text">{{inv.user_address}}</p>
                            <p class="card-text">{{inv.user_city == 'Others' ? '' : inv.user_city}}</p>
                            <p class="card-text">{{inv.user_prov == 'Others' ? '' : inv.user_prov}}</p>
                            <p class="card-text">{{inv.user_country}}</p>
                        </b-col>
                        <b-col xl="6" cols="12" class="invoice-recipient pb-0 pr-0">
                            <h5 class="mb-2">Recipient :</h5>
                            <h6 class="card-text name mb-1">{{inv.recipient_name ? inv.recipient_name.toUpperCase() : '-'}}</h6>
                            <table>
                                <tbody>
                                    <tr v-if="inv.acc_number"><td class="pr-3">{{inv.des_country == 'Argentina' ? 'CBU' :'Account Number'}}</td><td>{{inv.acc_number}}</td></tr>
                                    <tr v-if="inv.iban"><td class="pr-3">IBAN</td><td>{{inv.iban}}</td></tr>
                                    <tr v-if="inv.phone_n"><td class="pr-3">Phone</td><td>{{inv.phone_n}}</td></tr>
                                    <tr v-if="inv.bank_name"><td class="pr-3">Bank name</td><td>{{inv.usd_bank ? inv.usd_bank + ' (' + inv.bank_name + ')'  : inv.bank_name}}</td></tr>
                                    <tr v-if="inv.bsb_code"><td class="pr-3">BSB</td><td>{{inv.bsb_code}}</td></tr>
                                    <tr v-if="inv.transit_code"><td class="pr-3">Transit Code</td><td>{{inv.transit_code}}</td></tr>
                                    <tr v-if="inv.branch_name"><td class="pr-3">Branch Name</td><td>{{inv.branch_name}}</td></tr>
                                    <tr v-if="inv.branch_code"><td class="pr-3">Branch Code</td><td>{{inv.branch_code}}</td></tr>
                                    <tr v-if="inv.ifsc"><td class="pr-3">IFSC</td><td>{{inv.ifsc}}</td></tr>
                                    <tr v-if="inv.bik_code"><td class="pr-3">BIK Code</td><td>{{inv.bik_code}}</td></tr>
                                    <tr v-if="inv.swift"><td class="pr-3">SWIFT</td><td>{{inv.swift}}</td></tr>
                                    <tr v-if="inv.address"><td class="pr-3">Address</td><td>{{inv.address}}</td></tr>
                                    <tr v-if="inv.bank_add"><td class="pr-3">Bank Address</td><td>{{inv.bank_add}}</td></tr>
                                </tbody>
                            </table>
                        </b-col>
                    </b-row>
                </b-card-body>

                <hr class="invoice-spacing">
                <b-card-body class="invoice-padding pt-0">
                    <h5 class="title-send">Send details :</h5>
                    <b-row class="invoice-spacing">
                        <b-col cols="4" xl="4" class="invoice-send">
                            <table>
                                <tbody>
                                    <tr><td class="pr-4">Purpose</td><td>{{inv.purpose}}</td></tr>
                                    <tr><td class="pr-4">Source</td><td>{{inv.source}}</td></tr>
                                    <tr v-if="inv.note"><td class="pr-4">Note</td><td>{{inv.note}}</td></tr>
                                </tbody>
                            </table>
                        </b-col>
                        <b-col cols="5" xl="5" class="invoice-send">
                            <div class="invoice-total-wrapper">
                                <div class="invoice-total-item">
                                    <p class="invoice-total-title">Destination Country</p>
                                    <p class="invoice-total-amount">{{inv.des_country}}</p>
                                </div>
                                <div class="invoice-total-item">
                                    <p class="invoice-total-title">Amount</p>
                                    <p class="invoice-total-amount">{{inv.curr_code ? inv.curr_code.substring(0,3) : '-'}} {{Number(inv.amount) %1 != 0 ? formatPrice(inv.amount) : formatPriceRP(inv.amount)}}</p>
                                </div>
                                <div class="invoice-total-item">
                                    <p class="invoice-total-title">Rate</p>
                                    <p class="invoice-total-amount">IDR {{inv.des_country == 'Vietnam' ? formatPriceVND(inv.rate) : Number(inv.rate) >= 1000 ? formatPriceRP(inv.rate) : formatPrice(inv.rate) }}</p>
                                </div>
                                <div class="invoice-total-item">
                                    <p class="invoice-total-title">Fee</p>
                                    <p class="invoice-total-amount">IDR {{formatPriceRP(inv.fee)}}</p>
                                </div>
                                <hr class="my-50">
                                <div class="invoice-total-item strong">
                                    <p class="invoice-total-title">Total</p>
                                    <p class="invoice-total-amount">IDR {{formatPriceRP(Number(inv.send) + Number(inv.fee))}}</p>
                                </div>
                            </div>
                        </b-col>
                        <b-col cols="3" class="mt-md-6">
                            <div class="invoice-total-wrapper">
                                <div class="invoice-total-item">
                                    <img v-if="inv.status === 'Transaction Completed'" src="../../assets/images/success.svg" width="200rem" class="ml-5" alt="">
                                    <img v-if="inv.status === 'Order Cancelled'" src="../../assets/images/cancelled.svg" width="200rem"  class="ml-5" alt="">
                                    <img v-if="inv.status === 'Transaction Processing'" src="../../assets/images/proces.svg" width="200rem"  class="ml-5" alt="">
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-card-body>

                <!-- <hr class="invoice-spacing">

                <b-card-body class="invoice-padding pt-0 pl-5">
                    <span class="font-weight-bold">Note: </span>
                    <span>If you have any questions about this invoice, please contact us</span>
                </b-card-body> -->
              </b-card>
            </b-col>
          </b-row>
          <b-row class="invoice-small"><p>Open in Bigger Screen!!</p></b-row>
        </template>
      </form>
    </b-modal>
    <div class="customizer-setting d-none d-md-block" v-if="!loading && spesial.length != 0">
      <div class="btn-white btn-rounded shadow-lg p-4">
        <div class="ribbon-wrapper">
          <div class="ribbon ribbon-clip">Special Rate</div>
          <!-- <p class="subtitle"><b>Time left : 2 Hours left</b></p> -->
          <ul class="list-unstyled activity-wid pt-3">
              <li v-for="(data, index) in spesial" :key="index" class="hovered" v-on:click="selected2(data)">
                <div class="row pb-1" >
                  <div class="col-2">
                    <img :src="data.flag" class="image-icon" alt="country_flag">
                  </div>
                  <div class="col-5">
                    <h5>{{data.country}}</h5>
                  </div>
                  <div class="col-4 ml-1">
                    <h5>{{data.country == 'Vietnam' ? formatPriceVND(data.rate) : data.rate >= 1000 ? formatPriceRP(data.rate) : formatPrice(data.rate)}}</h5>
                  </div>
                </div>
              </li>
            </ul>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style scoped>
.marquee {
	color: black;
	letter-spacing: 2px;
  background-color:#167df0;
}
.customizer-setting{
  position:fixed;
  bottom:40px;
  left:40px;
  z-index:10000;
}
.btn-white{
  background: white;
  border: 1px solid black;
}
.invoice-small {
  display: none;
}
@media (max-width: 1000px) {
  .invoice-preview {
    display: none;
  }
  .invoice-small {
    display: block;
    margin-left:25%;
    font-weight: 900;
    font-size: 1.2rem;
    color: lightgray;
  }
}
</style>